<template>
   <div class="z-50 py-4 fixed h-screen w-screen inset-0 bg-body bg-opacity-25 bg-blur flex justify-center items-center">
        <div class="w-10/12 md:w-3/5 px-2 pt-4 pb-8 bg-module rounded-xl relative mx-auto">
            <span v-if="!isSearch" @click="openFilter" style="right:2rem; top: 2rem" class="text-main text-lg absolute">
                Aceptar
            </span>
            <span v-else @click="closeModalSearch" style="right:2rem; top: 2rem" class="text-main text-lg absolute">
                Cancelar
            </span>
            <Headline>
                <template slot="headlineTitle">
                    <slot name="modalTitle"></slot>
                </template>
                <template slot="headlineSubtitle">
                    <slot name="modalSubtitle"></slot>
                </template>        
            </Headline>   
            <div class="w-full px-4 my-4 flex flex-col">
                <div v-if="isRoute == 'Traffic'" 
                    @click="setSelected(allAreas)" 
                    class="w-full rounded-full px-4 py-3 mb-2 border-body border flex-center text-secondary"
                    :class="{ 'border-main': selected.id == allAreas.id}"
                    >{{allAreas.name}}
                </div>
                
                <div 
                    v-for="(option, index) in options" 
                    @click="setSelected(option)"
                    :key="index" 
                    class="w-full rounded-full px-4 py-3 mb-2 border-body border flex-center text-secondary"
                    :class="{ 'border-main': selected && (selected.id === option.id)}" >
                    {{option.name}}
                </div>
            </div>
            <template v-if="isSearch && searchCourses">
                <div class="max-h-120 h-auto overflow-y-auto">
                    <div v-for="searchCourse in searchCourses" :key="searchCourse.id" @click="goToCourse(searchCourse)" class="rounded-lg flex shadowData items-center py-3 my-3 pr-2 w-11/12 mx-auto bg-box relative">
                        <div style="top: .5rem; right: .4rem" class="absolute bg-mainSelected rounded-full px-2">
                            <span class="text-xs text-main">{{ searchCourse.com_edition_name }}</span>
                        </div>
                        <div class="w-3 rounded-l-lg h-full"></div>
                        <!-- <div class="rounded-full h-16 w-16 bg-body mx-4 flex flex-col">
                            <span class="text-xl text-main font-semibold m-auto"><slot name="searchResults"></slot><span class="text-sm">%</span></span>
                        </div> -->
                        <div class="flex-1 flex flex-col mt-2">
                            <span class="text-xs text-main"><slot name="searchResults"></slot>{{ searchCourse.area_name }}</span>
                            <span class="text-text text-left leading-none"><slot name="searchResults"></slot>{{ searchCourse.name }}</span>
                            <div class="flex items-center">
                                <i class="mdi mdi-calendar-start text-main not-italic mr-2 text-lg"></i>
                                <span class="text-secondary text-sm"><slot name="searchResults"></slot>{{ searchCourse.com_end_date | longDateFormat }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
                            
    </div>
</template>

<script>
import Headline from "@/pages/inside/sections/dashboards/Headline"

export default {
    name: 'Modal',
    components: {
        Headline,
    },
    props: {
        selected: null,
        isSearch: null,
        searchCourses: null,
        search: null,
        options: {
            type: Array,
            required: false
        },
    },
    data() {
        return {
            filter: false,
            allAreas: {
                id: null,
                name: 'Todas las áreas',
            }
        }
    },
    computed: {
        isRoute() {
            return this.$route.name
        }
    },
    methods: {
        openFilter() {
            this.$emit('openFilter')
        },
        closeModalSearch() {
            this.$emit('closeModalSearch')
        },
        setSelected(item) {
            this.$emit('setSelected', item)
        },
        goToCourse(course) {
            console.log(course.id,'course.id');
            console.log(course.com_edition_id,'course.com_edition_id');
            this.$router.push('/selection/' + course.id + '/' + course.com_edition_id);
        },
    }
}
</script>

<style>

</style>