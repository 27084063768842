<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.403 23.73" class="fill-current">
   <defs>
    <clipPath id="clip-path">
      <rect id="Rectángulo_6" data-name="Rectángulo 6" width="24.403" height="23.73" fill="#fac836"/>
    </clipPath>
  </defs>
  <g id="Grupo_3" data-name="Grupo 3" clip-path="url(#clip-path)">
    <path id="Trazado_1" data-name="Trazado 1" d="M10.893.825,7.914,6.96,1.25,7.947a1.49,1.49,0,0,0-.807,2.53l4.821,4.773-1.14,6.743A1.464,1.464,0,0,0,6.24,23.555L12.2,20.372l5.962,3.184a1.465,1.465,0,0,0,2.116-1.562l-1.14-6.743,4.821-4.773a1.49,1.49,0,0,0-.807-2.53L16.489,6.96,13.511.825A1.451,1.451,0,0,0,10.893.825Z" transform="translate(-0.001 0.001)" fill="#fac836"/>
  </g>
</svg>

</template>

<script>
export default {
    name: "StarIcon"
}
</script>

<style scoped>

</style>