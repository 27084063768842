<template>
    <div v-if="course.com_edition_id" @click="goToCourse" class="rounded-lg flex shadowData items-center h-24 my-3 pr-2 w-11/12 mx-auto bg-box relative">
        <div style="bottom: .5rem; right: .4rem" class="absolute bg-mainSelected rounded-full px-2">
            <span class="text-xs text-main">{{ course.com_edition_name }}</span>
        </div>
        <!-- TODO: MIRAR DISEÑO ESTRELLA CON PAULA -->
        <div class="absolute ml-16 mb-10 pl-3">
            
            <span
                v-if="course.com_is_star !== 0 && course.com_is_star !== null"
            >
                 <StarIcon class="w-4 h-4"></StarIcon>
            </span>
        </div>
        <div class="w-3 rounded-l-lg h-full" :class="status(course.com_light)"></div>
        <div class="rounded-full h-16 w-16 bg-body mx-4 flex flex-col">
            <span class="text-xl text-main font-semibold m-auto">{{ course.customers_target_percent_reached | numberFormat(0) }}<span class="text-sm">%</span></span>
        </div>
        <div class="flex-1 flex flex-col mt-2">
            <span class="text-text text-left leading-none">{{ course.name }}</span>
            <div class="flex items-center">
                <i class="mdi mdi-calendar-start text-main not-italic mr-2 text-lg"></i>
                <span class="text-secondary text-sm">{{ course.com_end_date | longDateFormat }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import StarIcon from "@/assets/StarIcon";
    export default {
        name: 'BoxCourse',
        components: {StarIcon},
        props: ['course'],
        methods: {
            goToCourse() {
                this.$router.push('/selection/' + this.course.id + '/' + this.course.com_edition_id);
            },
            status(status) {
                return {
                    'bg-good' : status == 'good', 
                    'bg-regular' : status == 'regular', 
                    'bg-bad' : status == 'bad', 
                }
            }
        }
    }
</script>