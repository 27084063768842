<template>
  <div class="w-full flex justify-between items-end px-4 py-2">
        <div class="flex flex-col items-start" :class="{'w-full' : full, 'w-2/3' : !full}">
            <h2 class="text-white text-2xl font-medium leading-tight"> <slot name="headlineTitle"></slot></h2>
            <div class="text-secondary text-sm"><slot name="headlineSubtitle"></slot></div>
        </div>
        <div v-if="!full" class="w-1/3 flex justify-end items-center">
            <i v-if="isFilter" class="mdi mdi-filter-variant text-secondary text-xl mr-2"></i>
            <div class="text-main font-medium"><slot name="headlineAction"></slot></div>
        </div>
  </div>
</template>

<script>
export default {
    name: 'Headline',
    props: {
        isFilter: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        },
    }
}
</script>

<style>

</style>