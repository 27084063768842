<template>
    <div>
        <Rotate />
        <transition name="fade" mode="out-in" class="z-50">
        <div class="w-screen md:w-3/5  max-w-mobile lg:max-w-tablet h-full min-h-screen mx-auto bg-body flex flex-col no-mobile-shadow">
            <Header @openSearch="openSearch" :closeSearch="closeSearch">
                <template #menuIcon>
                    <div @click="backToMainDashboard" class="bg-main rounded-2xl w-12 h-12 flex-center text-white mr-6">
                        <i class="mdi mdi-chevron-left text-5xl"></i>
                    </div>
                </template>
                <template #edemLogo>
                    <div></div>
                </template>
            </Header>


            <transition name="fade" mode="out-in" class="z-50">
                <div v-if="searchField" class="w-full flex justify-center items-center mx-2 z-50 relative">
                    <input type="text" id="searchText" v-model="search" class="search-input focus:outline-none bg-module h-10 w-8/12 px-4 text-white" @keyup.enter="goSearch" placeholder="Buscar curso..." />
                    <div @click="goSearch" class="bg-main text-white w-10 h-10  flex-center"><i class="mdi mdi-magnify text-xl"></i></div>
                    <div class="bg-box text-purple w-10 h-10 rounded-lg flex-center search-clean" @click="cleanSearch"><i class="mdi mdi-close-circle"></i></div>
                </div>
            </transition>

            <All model="Course" ref="searchCoursesRef" :query="{text:search, scope:'com'}" @success="setSearchCourses" />

            <div class="w-full">
                <All model="Area" :immediate="true" ref="areasRef" @success="setAreas">
                    <Carousell
                        class="h-full w-full"
                        :maxLength="areas ? areas.length : 0"
                        v-slot="{ active }"
                        :defaultActiveIndex="defaultActiveIndex"
                        @changeActiveIndex="changeActiveIndex"
                    >
                        <CarousellItem
                            v-for="(option, index) in areas"
                            :key="index"
                            class="transition-all duration-500 ease-in-out"
                            :class="{
                            'transform translate-x-48 text-sm': index < active,
                            'transform -translate-x-48 text-sm': index > active,
                            'transform text-lg text-main': index === active,
                        }"
                        >
                        
                            <div class="mt-2 flex flex-col h-16">
                                <span v-if="option.name">{{ option.name }}</span>
                                <div v-if="index === active" class="h-1 w-12 mt-4 bg-main mx-auto"></div>
                            </div>
                        </CarousellItem>
                    </Carousell>
                </All>
            </div>
            <portal v-if="showCalendar" to="modals">
                <transition name="fade" mode="out-in" appear>
                    <div class="absolute inset-0 w-screen h-screen bg-body bg-opacity-75 bg-blur z-50"></div>
                </transition>
                <transition name="slide-fade" mode="out-in" appear>
                    <div class="z-50 fixed h-screen w-screen inset-0 bg-body bg-opacity-25 bg-blur flex justify-center items-center">
                        <div class="bg-calendar rounded-t-lg w-full absolute bottom-0">
                            <img class="w-full h-64 absolute bottom-0 left-0 right-0 z-10 opacity-75" style="mix-blend-mode: overlay;" src="img/fish-left.png" />
                            <div class="flex items-center justify-between px-4 py-2">
                                <div class="flex items-center">
                                    <i class="mdi mdi-calendar-range text-main text-4xl mr-2"></i>
                                    <span class="text-main text-xl font-semibold">Filtrar</span>
                                </div>
                                <span @click="openCalendar" class="text-main text-lg">Aplicar</span>
                            </div>
                            <div class="relative z-50">
                                <date-picker 
                                style="border: none;"
                                v-model="range"
                                is-expanded 
                                :rows="1" 
                                is-dark 
                                is-range
                                />
                            </div>
                        </div>
                    </div>
                </transition>
            </portal>

            <portal v-if="showSearchResults" to="modals">
                <transition name="fade" mode="out-in" appear>
                    <div class="absolute inset-0 w-screen h-screen bg-body bg-opacity-75 bg-blur z-50"></div>
                </transition>
                <transition name="slide-fade" mode="out-in" appear>
                    <Modal :isSearch="true" :selected="selected" :searchCourses="searchCourses" @closeModalSearch="closeModalSearch">
                        <template slot="modalTitle">
                            <span class="text-3xl">Buscando</span>
                        </template>
                        <template slot="modalSubtitle">
                            "{{search}}"
                        </template>
                    </Modal>
                </transition>                
            </portal>
            
            <SelectionCourses class="-mt-4" :area="area" :range="range" />
        </div>
        </transition>
    </div>
</template>

<script>
    import { Carousell, CarousellItem } from '@/pages/inside/sections/dashboards/carousell';
    import Header from "@/pages/inside/sections/dashboards/Header"
    import Rotate from "@/pages/inside/sections/dashboards/Rotate"
    import SelectionCourses from '@/pages/inside/sections/dashboards/selection/SelectionCourses'
    import All from "../../../../api/components/All";
    import Modal from "@/pages/inside/sections/dashboards/Modal"
    
    import { actions } from "@/store";

    export default {
        
        name: 'DashboardSelection',
        components: {
            All,
            Header,
            Rotate,
            CarousellItem,
            Carousell,
            SelectionCourses,
            Modal,
        },
        data() {
            return {
                showCalendar: false,
                range: '',
                areas: [],
                area: null,
                defaultActiveIndex: 0,
                search: '',
                searchField: false,
                closeSearch: false,
                showSearchResults: false,
                searchCourses: false
            }
        },
        computed: {
            currentUrl() {
                return this.$route.params.areaId;
            }
        },
        watch: {
            range() {
                this.openCalendar()
            },
            areas(){
                let paramAreaId = parseInt(this.$route.params.areaId);

                for(let i = 0; i < this.areas.length; i++){
                    if(this.areas[i].id === paramAreaId){
                        this.area = this.areas[i];
                        this.defaultActiveIndex = i;
                        return;
                    }
                }
            },
            currentUrl(n) {
                let paramAreaId = parseInt(n);

                for(let i = 0; i < this.areas.length; i++){
                    if(this.areas[i].id === paramAreaId){
                        this.area = this.areas[i];
                        this.defaultActiveIndex = i;
                        return;
                    }
                }
            },
            search(){
                this.$refs.searchCoursesRef.request();
            }
        },
        methods: {
            openSearch() {
                this.searchField = !this.searchField;
                this.closeSearch = !this.closeSearch;
            },
            goSearch() {
                actions.setSearch(this.search);
                this.showSearchResults = !this.showSearchResults;
            },
            closeModalSearch() {
                this.showSearchResults = !this.showSearchResults;
            },
            cleanSearch() {
                this.search = '';
            },
            openCalendar() {
                this.showCalendar = !this.showCalendar
            },
            backToMainDashboard() {
                this.$router.push('/executive-summary');
            },
            setAreas(areas){
                this.areas = areas.data;
            },
            changeActiveIndex(activeIndex){
                this.area = this.areas[activeIndex];
            },
            setSearchCourses(searchCourses){
                this.searchCourses = searchCourses.data;
            },
        },
    }
</script>
