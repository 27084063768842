<template>
    <div class="w-full bg-module pt-4 flex-1 min-h-0 rounded-t-lg overflow-hidden flex flex-col">
        <Headline class="px-4" isFilter>
            <template slot="headlineTitle">
                <span class="text-3xl">Cursos</span>
            </template>
            <template slot="headlineSubtitle">
                {{selected.name}}
            </template>
            <template slot="headlineAction">
                <span @click="openFilter">Ordenar</span>
            </template>
        </Headline>

        <div class="flex-1 min-h-0 w-full mt-3 overflow-auto">
            <All model="Course" :immediate="true" ref="coursesRef" v-if="areaId" v-slot="{data:courses}" :query="coursesQuery">
                <div>
                    <BoxCourse v-for="(course,cindex) in courses.data" :key="cindex" :course="course" />
                </div>
            </All>
        </div>

        <portal v-if="filter" to="modals">
            <transition name="fade" mode="out-in" appear>
                <div class="absolute inset-0 w-screen h-screen bg-body bg-opacity-75 bg-blur z-50"></div>
            </transition>
            <transition name="slide-fade" mode="out-in" appear>
                <Modal @setSelected="setSelected" @openFilter="openFilter" :selected="selected" :options="options">
                    <template slot="modalTitle">
                        <span class="text-3xl">Ordenar</span>
                    </template>
                    <template slot="modalSubtitle">
                        Cursos
                    </template>
                </Modal>
            </transition>
            
        </portal>
    </div>
</template>

<script>
    import Headline from "@/pages/inside/sections/dashboards/Headline";
    import Modal from "@/pages/inside/sections/dashboards/Modal";
    import BoxCourse from "@/pages/inside/sections/dashboards/selection/BoxCourse";
    import All from "../../../../../api/components/All";
    // import Vue2Filters from 'vue2-filters';

    export default {
        // mixins: [Vue2Filters.mixin],
        props:['area','range'],
        name: 'SelectionCourses',
        components: {
            All,
            Headline,
            BoxCourse,
            Modal
        },
        data() {
            return {
                selected: {name: 'Por fecha de inicio', id: 0},
                filter: false,
                options: [
                    {name: 'Por fecha de inicio', id: 0, sortField: 'editions.com_end_date', sortDirection: 'ASC'},
                    // {name: 'De peor a mejor', id: 1, sortField: 'snapshots.customers_target_percent_reached', sortDirection: 'ASC'},
                    // {name : 'De mejor a peor', id: 2, sortField: 'snapshots.customers_target_percent_reached', sortDirection: 'DESC'},
                    {name: 'Mayor número de alumnos', id: 3, sortField: 'snapshots.customers', sortDirection: 'DESC'},
                    // {name: 'Mayor número de oportunidades', id: 4, sortField: 'snapshots.opportunities', sortDirection: 'DESC'}
                ],
                sortField: 'com_end_date',
                sortDirection: 'ASC'

            }
        },
        computed: {
            areaId(){
                return this.area ? this.area.id : 0;
            },
            coursesQuery() {
                return {
                    area: this.areaId,
                    scope: 'com',
                    field: this.sortField,
                    direction: this.sortDirection
                }
            }
        },
        methods: {
            openFilter() {
                this.filter = !this.filter
            },

            setSelected(selected) {
                this.selected = selected;
                this.sortField = selected.sortField;
                this.sortDirection = selected.sortDirection;
            },
        },
        watch: {
            coursesQuery(){
                if(this.$refs.coursesRef){
                    this.$refs.coursesRef.request();
                }
            }
        }
    }
</script>